/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Styles from './App.module.css';
import * as ShopifyApis from './services/ShopifyApis';

function App() {
  const [state, setState] = useState({ products: [] });
  useEffect(() => {
    (async () => {
      const result = await ShopifyApis.getCollections();
      setState({ ...state, products: result.products });
    })();
  }, []);
  return (
    <div>
      {state.products.map((p) => (
        <div className={Styles.test}>{p.title}</div>
      ))}
    </div>
  );
}

export default App;
